import React from 'react'
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const HomePricingSection = () => (
  <div id="financial-services" className="w-full bg-gray-100">
    <div className='w-full bg-site-red'>
      <div className="w-11/12 py-14 md:py-20 lg:py-24 mx-auto">
        <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>Financial Services & Reporting</h2>
      </div>
    </div>

    <div className='relative w-11/12 max-w-screen-xl bg-gray-100 py-14 md:py-20 lg:py-24 lg:w-10/12 mx-auto'>
      <div className='flex flex-col md:flex-row items-start justify-center'>
        <div className='w-full lg:w-3/6 flex flex-col justify-center mb-10 lg:mb-0'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Financial Reports</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed pr-0 md:pr-8'>Tiarna can customize reports to the needs of our clients. All financial reporting activity is consolidated on a monthly basis. Financial reports are delivered to our clients each month, bound with the table of contents that identifies the activities of the previous month. Variances between actual and budget numbers are identified and explained through detailed narrative. Ongoing operations such as capital improvements, leasing activities or possible litigation are identified for reference and status purposes.</p>
        </div>

        <div className='w-full lg:w-3/6 flex flex-col justify-center'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Annual Budgets</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed'>Our clients receive an annual budget that identifies the projected operations, capital expenditures and leasing activity for each asset. This budget identifies detailed explanations for all sources of income, operating expenses, capital expenditures, and owner expenditures for the coming calendar year. This plan is submitted in person by Tiarna’s Project Team for detailed review with our client. A final budget is bound and distributed in hard copy along with an electronic version with key tabs for easy access to project data.</p>
        </div>
      </div>
    </div>
  </div>
)

export default HomePricingSection