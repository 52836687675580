import React from "react"
import { PhoneIcon } from '@heroicons/react/outline'
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  background: #A6192E;
`

const LocationsSectionNew = () => (
  <div id="locations" className="w-full bg-site-blue">
    <div className="w-full max-w-screen-xl flex flex-col items-center justify-center mx-auto py-14 md:py-20 lg:py-24 px-6 xl:px-14">
      <div className="w-11/12 md:w-6/12 mb-12">
        <h2 className='font-display text-white text-4xl md:text-5xl font-bold text-center mb-6 leading-tight'>Corporate Locations</h2>

        <StyledUnderline className='mb-6'></StyledUnderline>

        <p className="text-white text-xl text-center">We have several executive offices to better serve our communities.</p>
      </div>

      <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-8 place-content-center mx-auto'>
        <div className='w-full flex flex-col items-center justify-center'>
          <p className="text-2xl text-white font-semibold text-center mt-4">
            Orange County Corporate Office
          </p>
          <p className="text-xl text-site-red font-semibold text-center mt-2 mb-4">
            2603 Main Street, Suite 210,<br className="invisible md:visible" /> Irvine, CA 92614
          </p>
          <div className="flex items-center mb-4">
            <PhoneIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
            <a href="tel:949-833-8334" className="text-white text-xl font-semibold hover:underline ml-1">949-833-8334</a>
          </div>

          <div className="flex flex-col items-center justify-center mt-4 mb-6">
            <a
              href='https://goo.gl/maps/15cHJZxWZbMQyP5c6'
              target='_blank'
              rel='noopener noreferrer'
              className='w-full md:w-auto bg-site-blue hover:bg-blue-900 text-white text-lg text-center tracking-wider uppercase font-extrabold border-4 py-2 px-12 md:px-8'
            >
              Get Directions
            </a>
          </div>
        </div>

        <div className='w-full flex flex-col items-center justify-center'>
          <p className="text-2xl text-white font-semibold text-center mt-4">
            Los Angeles Office
          </p>
          <p className="text-xl text-site-red font-semibold text-center mt-2 mb-4">
            6345 N. Balboa Boulevard, Suite 280,<br className="invisible md:visible" /> Encino, CA 91316
          </p>
          <div className="flex items-center mb-4">
            <PhoneIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
            <a href="tel:818-817-0550" className="text-white text-xl font-semibold hover:underline ml-1">818-817-0550</a>
          </div>

          <div className="flex flex-col items-center justify-center mt-4 mb-6">
            <a
              href='https://goo.gl/maps/suskUquaYF3HrWzQ9'
              target='_blank'
              rel='noopener noreferrer'
              className='w-full md:w-auto bg-site-blue hover:bg-blue-900 text-white text-lg text-center tracking-wider uppercase font-extrabold border-4 py-2 px-12 md:px-8'
            >
              Get Directions
            </a>
          </div>
        </div>

        <div className='w-full flex flex-col items-center justify-center'>
          <p className="text-2xl text-white font-semibold text-center mt-4">
            Inland Empire Office
          </p>
          <p className="text-xl text-site-red font-semibold text-center mt-2 mb-4">
            685 E. Carnegie Drive, Suite 110,<br className="invisible md:visible" /> San Bernardino, CA 92408
          </p>
          <div className="flex items-center mb-4">
            <PhoneIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
            <a href="tel:949-833-8334" className="text-white text-xl font-semibold hover:underline ml-1">949-833-8334</a>
          </div>

          <div className="flex flex-col items-center justify-center mt-4 mb-6">
            <a
              href='https://maps.app.goo.gl/iJ5E643pzcsBWD3t8'
              target='_blank'
              rel='noopener noreferrer'
              className='w-full md:w-auto bg-site-blue hover:bg-blue-900 text-white text-lg text-center tracking-wider uppercase font-extrabold border-4 py-2 px-12 md:px-8'
            >
              Get Directions
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LocationsSectionNew