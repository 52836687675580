import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const HomeAboutSectionUpdate = () => (
  <div id="about" className="w-full bg-gray-100">
    <div className="w-full max-w-screen-xl flex flex-col md:flex-row items-center justify-center mx-auto py-14 md:py-20 lg:py-24 px-6 xl:px-14">
      <div className="w-11/12 md:w-6/12 md:pr-8 mb-6 md:mb-0">
        <h2 className='font-display text-site-blue text-4xl md:text-5xl font-bold mb-6 leading-tight'>About Us</h2>

        <StyledUnderline className='mb-6'></StyledUnderline>

        <p className="text-site-blue text-xl mb-6">Tiarna was founded in 2000 by a close-knit group of industry veterans who share a common vision of superior client service. Today we manage over 16 million square feet of real estate for institutional and individual clients.</p>

        <div className="flex flex-col items-center lg:flex-row">
          <Link to="team">
            <button
              className='bg-site-blue hover:bg-blue-900 text-white text-xl font-extrabold tracking-wider uppercase px-8 py-5 mb-6 lg:mb-0 lg:mr-6'
            >
              Our Team
            </button>
          </Link>

          <Link to="careers">
            <button
              className='bg-site-red hover:bg-red-600 text-white text-xl font-extrabold tracking-wider uppercase px-8 py-5'
            >
              Join Us
            </button>
          </Link>
        </div>
      </div>

      <div className="w-11/12 md:w-6/12">
        <StaticImage
          alt="Tiarna Office"
          src={
            "../../images/sections/tiarna-office.png"
          }
          formats={["auto", "webp", "avif"]}
          width={664}
        />
      </div>
    </div>
  </div>
)

export default HomeAboutSectionUpdate