const menuItems = [
  {
    path: "home",
    label: "Home",
  },
  {
    path: "advantage",
    label: "Advantage",
  },
  {
    path: "services",
    label: "Services",
  },
  {
    path: "financial-services",
    label: "Financial Services",
  },
  {
    path: "locations",
    label: "Locations",
  },
]

export default menuItems