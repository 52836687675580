import * as React from "react"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'react-scroll'
import styled from 'styled-components'

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import HomeAboutSectionUpdate from "../components/sections/home-about-section-update"
import HomeAdvantageSection from "../components/sections/home-advantage-section"
import HomePricingSection from "../components/sections/home-pricing-section"
import HomeServicesSection from "../components/sections/home-services-section"
import LocationsSectionNew from "../components/sections/locations-section-new"

const StyledDiv = styled.div`
  .text-shadow-blue {
    text-shadow: 0 3px 3px #003057;
  }
`

const HomeHeroSectionUpdate = loadable(() =>
  pMinDelay(import('../components/sections/home-hero-section-update'), 3000),{
    fallback:
      <div className="hidden md:grid relative bg-site-blue">
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
            objectFit: "cover",
            opacity: "0.4",
            height: 600,
          }}
          // This is a presentational image, so the alt should be an empty string
          alt="First Home Hero Background"
          src={
            "../../images/backgrounds/home-hero-bg-1.jpg"
          }
          formats={["auto", "webp", "avif"]}
          width={1920}
          className="hidden md:block"
        />
        <div
          className="grid"
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
          }}
        >
          <div className="absolute w-16 md:w-32 lg:w-full bottom-0 left-0  mb-3 ml-1 md:mb-4 md:ml-4">
            <StaticImage
              alt="AMO Certified Badge"
              src={
                "../../images/sections/amo.png"
              }
              formats={["auto", "webp", "avif"]}
              width={150}
            />
          </div>
          {/* Any content here will be centered in the component */}
          <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
            <StyledDiv className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
              <h2 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
                <span className='leading-tight'>Management <span className="invisible md:visible text-site-red">|</span> Brokerage <br/> Receiverships <span className="invisible md:visible text-site-red">|</span> Advisory Services</span>
              </h2>

              <h1 className='w-full lg:w-9/12 md:text-xl text-white text-lg text-center text-shadow-blue my-6'><span className='leading-loose'>Tiarna Real Estate Services, Inc. is a comprehensive commercial real estate firm serving landlords in California, specializing in office, industrial and retail properties.</span></h1>

              <div className='flex flex-col items-center justify-center'>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <button
                    className='bg-site-blue hover:bg-blue-900 text-white text-xl font-extrabold tracking-wider uppercase px-8 py-2'
                  >
                    Discover More
                  </button>
                </Link>

                <h2 className="text-transparent text-xl mt-6 text-center">Learn More About Us</h2>
              </div>
            </StyledDiv>
          </div>
        </div>
      </div>
  }
)

const MobileHomeHeroSectionNew = loadable(() =>
  pMinDelay(import('../components/sections/mobile-home-hero-section-new'), 3000),{
    fallback:
      <>
        <div className="grid md:hidden relative bg-site-blue">
          {/* You can use a GatsbyImage component if the image is dynamic */}
          <StaticImage
            style={{
              gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // maxHeight: 600,
              objectFit: "cover",
              opacity: "0.4",
              height: 200,
            }}
            // This is a presentational image, so the alt should be an empty string
            alt="First Home Hero Background"
            src={
              "../../images/backgrounds/home-hero-bg-1.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={800}
            className="block md:hidden"
          />
          <div
          className="grid"
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
            }}
          >
            {/* Any content here will be centered in the component */}
            <div className="absolute w-16 md:w-32 lg:w-full bottom-0 left-0  mb-3 ml-1 md:mb-4 md:ml-4">
              <StaticImage
                alt="AMO Certified Badge"
                src={
                  "../../images/sections/amo.png"
                }
                formats={["auto", "webp", "avif"]}
                width={150}
              />
            </div>
          </div>
        </div>

        <div className='md:hidden bg-site-blue w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
          <StyledDiv className='bg-site-blue w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
            <h2 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
              <span className='leading-tight'>Management <span className="invisible md:visible text-site-red">|</span> Brokerage <br/> Receiverships <span className="invisible md:visible text-site-red">|</span> Advisory Services</span>
            </h2>

            <h1 className='w-full lg:w-9/12 md:text-xl text-white text-lg text-center text-shadow-blue my-6 px-4'><span className='leading-loose'>Tiarna Real Estate Services, Inc. is a comprehensive commercial real estate firm serving landlords in California, specializing in office, industrial and retail properties.</span></h1>

            <div className='flex flex-col items-center justify-center'>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <button
                  className='bg-site-blue hover:bg-blue-900 text-white text-xl font-extrabold tracking-wider uppercase px-8 py-2'
                >
                  Discover More
                </button>
              </Link>

              <h2 className="text-transparent text-xl mt-6 text-center">Learn More About Us</h2>
            </div>
          </StyledDiv>
        </div>
      </>
  }
)

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HomeHeroSectionUpdate />
    <MobileHomeHeroSectionNew />
    <HomeAboutSectionUpdate />
    <HomeAdvantageSection />
    <HomeServicesSection />
    <HomePricingSection />
    <LocationsSectionNew />
  </Layout>
)

export default IndexPage
