import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`
const StyledUnderline1 = styled.div`
  width: 80px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  background: #A6192E;
`

const HomeServicesSection = () => (
  <div id="services" className="w-full bg-gray-100">
    <div className='w-full bg-site-red'>
      <div className="w-11/12 py-14 md:py-20 lg:py-24 mx-auto">
        <h2 className='font-display text-white text-4xl md:text-5xl font-bold leading-tight text-center'>Our Services</h2>
      </div>
    </div>

    <div className='relative w-11/12 max-w-screen-xl bg-gray-100 py-12 md:py-16 lg:w-10/12 py-14 md:py-20 lg:py-24 mx-auto'>
      <div className='flex items-start flex-col-reverse lg:flex-row-reverse mb-16'>
        <div className='w-full lg:w-3/6 flex flex-col justify-center'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Real Estate Management</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed'>Tiarna’s management expertise encompasses a diverse portfolio of high-rise office buildings, industrial properties, retail centers, and medical office buildings. Our property managers have an average of 22 years of experience in the industry and most hold or are working towards their Certified Property Manager (CPM®) or Certified Shopping Center Manager (CSM®) designation. Tiarna's founder is a senior instructor for the Institute of Real Estate Management (IREM®). Tiarna's property managers are fully supported by an exceptional team of assistants, building engineers, and a full service accounting department under the supervision of a CPA. We are capable of handling large-scale institutional portfolios, yet flexible enough to meet the specific needs of a private, single-building owner.</p>
        </div>

        <div className='hidden md:block w-full lg:w-3/6 mb-6 lg:mb-0 lg:ml-0 lg:mr-8'>
          <StaticImage
            alt="First Service Background Image"
            src={
              "../../images/sections/services/service-1.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={1024}
          />
        </div>

        <div className='md:hidden w-full lg:w-3/6 mb-6 lg:mb-0 lg:ml-0 lg:mr-8'>
          <StaticImage
            alt="First Service Background Image"
            src={
              "../../images/sections/services/service-1.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={800}
          />
        </div>
      </div>

      <div className='flex items-start flex-col-reverse lg:flex-row  mb-16'>
        <div className='w-full lg:w-3/6 flex flex-col justify-center'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Leasing & Investment Sales</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed'>Tiarna’s team members specialize in leasing, brokerage and investment advisory services. Our teams of professionals develop strategies and comprehensive positioning programs to maximize the value of our client’s real estate. Our 75 years of combined experience on office, medical office, industrial and retail transactions provides our clients with the benefit of attaining a market transaction that fits within the operations of a given asset, both mechanically and financially.</p>
        </div>

        <div className='hidden md:block w-full lg:w-3/6 mb-6 lg:mb-0 ml-0 lg:ml-8'>
          <StaticImage
            alt="Second Service Background Image"
            src={
              "../../images/sections/services/service-2.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={1024}
          />
        </div>

        <div className='md:hidden w-full lg:w-3/6 mb-6 lg:mb-0 ml-0 lg:ml-8'>
          <StaticImage
            alt="Second Service Background Image"
            src={
              "../../images/sections/services/service-2.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={800}
          />
        </div>
      </div>

      <div className='flex items-start flex-col-reverse lg:flex-row-reverse mb-16'>
        <div className='w-full lg:w-3/6 flex flex-col justify-center'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Advisory Services / Due Diligence</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed'>Tiarna advises landlords throughout the life-cycle of an investment. During the acquisition phase, we research the market, provide investment underwriting analysis, and perform comprehensive due diligence. During the ownership phase, we advise our clients on value creation through traditional management and Lease Advisory Services. During the disposition phase, we advise on investment yield objectives and sales marketing strategies.</p>
        </div>

        <div className='hidden md:block w-full lg:w-3/6 mb-6 lg:mb-0 lg:ml-0 lg:mr-8'>
          <StaticImage
            alt="Third Service Background Image"
            src={
              "../../images/sections/services/service-3.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={1024}
          />
        </div>

        <div className='md:hidden w-full lg:w-3/6 mb-6 lg:mb-0 lg:ml-0 lg:mr-8'>
          <StaticImage
            alt="Third Service Background Image"
            src={
              "../../images/sections/services/service-3.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={800}
          />
        </div>
      </div>

      <div className='flex items-start flex-col-reverse lg:flex-row  mb-16'>
        <div className='w-full lg:w-3/6 flex flex-col justify-center'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Construction Administration</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed'>Tiarna’s comprehensive approach to every project is delivered by seasoned veterans with a solid background in construction administration and contract negotiations. Tiarna’s approach is to improve the value of our client’s real estate investments through a clear and defined scope of work, cost control, quality assurance, and meeting tight completion deadlines. Our years of experience in construction administration allow our clients to draw upon a vast array of services and expertise such as site assessment, due diligence, tenant improvements, building renovations, work letter negotiations, roof, and mechanical plant replacement.</p>
        </div>

        <div className='hidden md:block w-full lg:w-3/6 mb-6 lg:mb-0 ml-0 lg:ml-8'>
          <StaticImage
            alt="Fourth Service Background Image"
            src={
              "../../images/sections/services/service-4.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={1024}
          />
        </div>

        <div className='md:hidden w-full lg:w-3/6 mb-6 lg:mb-0 ml-0 lg:ml-8'>
          <StaticImage
            alt="Fourth Service Background Image"
            src={
              "../../images/sections/services/service-4.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={800}
          />
        </div>
      </div>

      <div className='flex items-start flex-col-reverse lg:flex-row-reverse  mb-16'>
        <div className='w-full lg:w-3/6 flex flex-col justify-center'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Receivership Services</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>

          <p className='text-site-blue text-lg leading-relaxed'>Receivership services provide impartial third-party control of the real estate and personal property to prevent waste and safeguard value for lenders. Having been appointed by the Superior Court as a Receiver on over 100 cases, Thomas McAndrews, President/CEO of Tiarna, has the expertise and experience to assist lenders in this critical area.</p>
        </div>

        <div className='w-full lg:w-3/6 mb-6 lg:mb-0 lg:ml-0 lg:mr-8'>
          <StaticImage
            alt="Fifth Service Background Image"
            src={
              "../../images/sections/services/service-5.jpg"
            }
            formats={["auto", "webp", "avif"]}
            width={845}
          />
        </div>
      </div>

      <div className="w-full max-w-screen-xl flex flex-col items-center justify-center mx-auto">
        <div className="w-11/12 md:pr-8 mb-6 md:mb-0">
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight text-center'>Full Service Accounting</h2>

          <StyledUnderline1 className='mb-6'></StyledUnderline1>

          <p className='text-site-blue text-lg leading-relaxed mb-6'>Tiarna’s Accounting Department is located in the company’s Irvine office providing our clients with easy access to our CPAs and Senior Accountants. Tiarna’s managers and accountants work side by side creating a truly integrated team that creates efficiencies and ensures consistency and accuracy for our clients.</p>

          <p className='text-site-blue text-lg leading-relaxed mb-6'>Tiarna’s extensive experience includes a broad range of internal and web based accounting platforms for institutional clients, corporations, developers, REITs, family trusts and individuals. Tiarna’s knowledge and experience includes the following platforms:</p>

          <div className='hidden md:flex w-full flex-col items-center justify-center'>
            <StaticImage
              alt="Set of logos of Accounting Softwares"
              src={
                "../../images/sections/services/accounting-softwares-new-3.png"
              }
              formats={["auto", "webp", "avif"]}
              width={1230}
            />
          </div>

          <div className='md:hidden w-full flex-col items-center justify-center'>
            <StaticImage
              alt="Set of logos of Accounting Softwares"
              src={
                "../../images/sections/services/accounting-softwares-new-2.png"
              }
              formats={["auto", "webp", "avif"]}
              width={800}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HomeServicesSection